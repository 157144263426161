<template>
    <footer class="bg-slate-50">
        <BaseContainer class="flex">
            <div class="flex flex-1 flex-col">
                <!-- eFirma logo -->
                <NuxtLink
                    :to="
                        localePath({
                            path: localePath('/'),
                            hash: '',
                            replace: true,
                        })
                    "
                    aria-label="Home"
                    class="self-start"
                    @click="scrollToTop"
                >
                    <AppLogo class="h-12" />
                </NuxtLink>

                <p class="text-gray-700">{{ t('description') }}</p>

                <BaseButton :to="registerUrl" class="my-6 self-start">
                    {{ t('register') }}
                </BaseButton>

                <div
                    class="flex flex-col gap-2 text-gray-500 sm:flex-row sm:items-center"
                >
                    <NuxtLink
                        :to="localePath('terms')"
                        class="text-nowrap hover:underline"
                    >
                        {{ t('terms') }}
                    </NuxtLink>

                    <span class="hidden sm:inline-block">{{ separator }}</span>

                    <NuxtLink
                        :to="localePath('privacy')"
                        class="text-nowrap hover:underline"
                    >
                        {{ t('privacy') }}
                    </NuxtLink>
                </div>

                <p class="mt-2 text-gray-500 sm:mt-0">
                    {{ t('copyright', { year }) }}
                </p>
            </div>

            <div class="hidden flex-col justify-end gap-5 text-right lg:flex">
                <NuxtLink
                    v-for="link in links"
                    :key="link.to"
                    :href="link.to"
                    class="font-medium text-gray-700 hover:text-blue-600"
                >
                    {{ link.label }}
                </NuxtLink>
            </div>
        </BaseContainer>
    </footer>
</template>

<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()
const { registerUrl } = useAppUrls()

const year = new Date().getFullYear()
const separator = '•'

const links = computed(() => ({
    features: {
        label: t('links.features'),
        to: localePath('/#features'),
    },
    plans: { label: t('links.plans'), to: localePath('/#plans') },
    aboutUs: { label: t('links.about-us'), to: localePath('/#about-us') },
    contacts: { label: t('links.contacts'), to: localePath('/#contact-us') },
}))
</script>

<i18n lang="yaml">
en:
    description: 'Simplified accounting platform, assisting small and large businesses.'
    register: 'Free registration'
    copyright: '© {year}. eFirma. All rights reserved'
    terms: 'Terms of Service'
    privacy: 'Privacy Policy'
    links:
        features: 'Key Features'
        plans: 'Plans'
        about-us: 'About Us'
        contacts: 'Contacts'
bg:
    description: 'Опростена платформа за счетоводство, подпомагаща малки и големи фирми.'
    register: 'Безплатна регистрация'
    copyright: '© {year}. eFirma. Всички права запазени'
    terms: 'Общи условия'
    privacy: 'Политика за поверителност'
    links:
        features: 'Функционалности'
        plans: 'Планове'
        about-us: 'За нас'
        contacts: 'Контакти'
</i18n>
