<template>
    <div>
        <label for="lang-select" class="sr-only" />
        <select
            id="lang-select"
            name="lang-select"
            required
            :value="locale"
            class="h-10 rounded-lg border-gray-200 !py-0 pr-8 text-sm uppercase text-blue-600"
            @change="onLocaleSelect"
        >
            <option
                v-for="lang in locales"
                :key="lang.code"
                :value="lang.code"
                :selected="lang.code === locale"
            >
                {{ lang.code }}
            </option>
        </select>
    </div>
</template>

<script setup lang="ts">
const { locale, locales } = useI18n()
const router = useRouter()
const localePath = useLocalePath()

function onLocaleSelect(event: Event) {
    const langCode = (event.target as HTMLSelectElement).value

    router.replace(
        localePath(
            {
                path: router.currentRoute.value.fullPath,
                hash: router.currentRoute.value.hash,
                replace: true,
            },
            langCode,
        ),
    )
}
</script>
